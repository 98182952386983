import canAutoPlay from 'can-autoplay';

const videoUrls = {
    landscape: {
        'mp4': 'https://video.bunnycdn.com/play/109909/2baa88fc-d848-45b3-957e-3a5a2989d276',
        'playlist': 'https://vz-6080a0fa-38a.b-cdn.net/2baa88fc-d848-45b3-957e-3a5a2989d276/playlist.m3u8',
    },
    portrait: {
        'mp4': 'https://video.bunnycdn.com/play/109909/e65d635f-6c87-4981-9afa-0df79573fb1a',
        'playlist': 'https://vz-6080a0fa-38a.b-cdn.net/e65d635f-6c87-4981-9afa-0df79573fb1a/playlist.m3u8',
    },
};

export default () => ({
    hls: null,

    async init() {
        await this.initVideo();
        if (this.$store.muted === false) {
            // Try enabling audio
            canAutoPlay
                .video({ timeout: 100, muted: false })
                .then(({ result }) => {
                    if (result === true) {
                        if(this.$refs.video) {
                            this.$refs.video.muted = this.$store.muted;
                        }
                    } else {
                        this.$store.muted = true;
                    }
                });
        }
    },

    async initVideo() {

        const isLandscape = window.matchMedia("(orientation: landscape)").matches;
        const videoUrl = isLandscape ? videoUrls.landscape : videoUrls.portrait;

        const video = this.$refs.video;
        let videoSrc = videoUrl.playlist;
        const { default: Hls } = await import('hls.js');

        if (Hls.isSupported()) {
            this.hls = new Hls({ startLevel: -1 });
            this.hls.loadSource(videoSrc);
            this.hls.attachMedia(video);
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            let source = document.createElement('source');
            source.setAttribute('src', videoSrc);
            video.appendChild(source);
        } else {
            let source = document.createElement('source');
            source.setAttribute('src', videoUrl.mp4);
            video.appendChild(source);
        }
    },

    toggleMute() {
        this.$store.muted = !this.$store.muted;
        if(this.$refs.video) {
            this.$refs.video.muted = this.$store.muted;
        }
    },

    destroy() {
        if (this.hls) {
            this.hls.destroy();
        }
    },
});
